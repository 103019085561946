/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import React from "react";
import { Content, ClassHelper } from "components/lib";
import { useNavigate } from "components/lib";
import AnimtrLogo from "../../logo/images/animatrLogo.png";
import Style from "./home.tailwind.js";

export function HomeNav(props) {
  const navigate = useNavigate();
  // context
  // const context = useContext(AuthContext);

  const css = ClassHelper(Style, {
    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent,
  });

  return (
    <section className={css}>
      <Content>

        <img onClick={()=>{
          navigate('/')
        }} src={AnimtrLogo} alt="logo" className={Style.logo} style={{height: '60px'}}/>
        {/* <Logo className={Style.logo} /> */}

        {/* <nav className={Style.nav}>
          <Link
            url="/pricing"
            text="ABOUT"
            className={Style.link}
            color="white"
          />

          <Link url="/#" text="NEWS" className={Style.link} color="white" />

          <Link url="/#" text="CONTACT" className={Style.link} color="white" />

          <Link url="/#" text="CREATE" className={Style.link} color="white" />

          {context.user?.token ? (
            <Button
              small
              goto="/dashboard"
              text="Dashboard"
              className={Style.button}
            />
          ) : (
            <Fragment>
              <Link url='/signin' text='Sign In' className={ Style.link } color='white'/>
              <Button
                small
                goto="/signup"
                text="Sign Up"
                className="inline-block"
              />
            </Fragment>
          )}
        </nav> */}
      </Content>
    </section>
  );
}

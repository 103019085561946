// /***
//  *
//  *   SIGN UP STEP 2
//  *   Signup form for account owners
//  *   Step 1: create account
//  *   Step 2: select plan and make payment
//  *
//  **********/

import React, { useContext, useState } from "react";
import {
  Animate,
  Seo,
  // AuthContext,
  Row,
  Button,
  AuthContext,
  // Card,
  // PaymentForm,s
  // usePlans,
  // useNavigate,
  // Event,
} from "components/lib";
import PlanCard from "components/card/planCard";
import { BillingCard } from "views/account/billing/card";

export function SignupPlan(props) {
  const context = useContext(AuthContext);
  const [checked, /*setChecked*/] = useState(false);
  const [showCreditCard, setShowCreditCard] = useState(false)
  const [tokenId, setTokenId] = useState('')

  // const handleChange = () => {
  //   setChecked(!checked);
  // };

  return (
    <div style={{backgroundColor: '#FFED36'}}>
      <Animate type="pop">
        <Seo title="Select Plan" description="The right plan for you" />
        {
          !showCreditCard ?
            <Row
              title="The Right Plan for You"
              fontCss
              customeStyle={{ fontSize: "26px", fontWeight: "bold", fontFamily: 'Poppins', color: '#231F20' }}
            >
              <p className="text-[#231F20] text-22 text-center items-center font-semibold font-poppins">
                We have 3 plans now. Need a customized solution?
              </p>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 8}}>
                <Button text="Contact us"  url='https://www.animatr.ai/contact-copy' rounded/>
              </div>
              <div className="flex justify-around mt-10">
                <div className="grid grid-cols-3 gap-4 mb-12 -mx-6 plan-card-container">
                  <PlanCard
                    planName="Free"
                    price="0"
                    plan="free"
                    checked={checked}
                    // tokenId={tokenId}
                    subscription={!checked ? "monthly" : "yearly"}
                    planFeatures={[
                      "1 video credit = 5 sec video ",
                      "Text to video (perfecting your script coming soon)",
                      "4 different voice styles",
                      "Automatically animated",
                      "6 output languages",
                    ]}
                    credits={3}
                    url="/api/account/plan"
                  />
                  <PlanCard
                    planName="Basic"
                    price="29"
                    buttonText="Buy now"
                    plan="price_1L0XlWG2u4eiINVDXwlSHLKn"
                    checked={checked}
                    tokenId={tokenId}
                    subscription={!checked ? "monthly" : "yearly"}
                    planFeatures={[
                      "1 video credit = up to 30 sec video",
                      "Text to video (perfecting your script coming soon)",
                      "4 different voice styles",
                      "Automatically animated",
                      "6 output languages",
                    ]}
                    setShowCreditCard={setShowCreditCard}
                    credits={10}
                    url={`https://buy.stripe.com/eVa9DH8vL3JA1J6bII?client_reference_id=${context.user.account_id}`}
                  />
                  <PlanCard
                    planName="Pro"
                    price="89"
                    tokenId={tokenId}
                    buttonText="Buy now"
                    plan="price_1L9tvSG2u4eiINVDFz1sVmRZ"
                    checked={checked}
                    subscription={!checked ? "monthly" : "yearly"}
                    setShowCreditCard={setShowCreditCard}
                    planFeatures={[
                      "1 video credit = up to 30 sec video",
                      "Text to video (perfecting your script coming soon)",
                      "4 different voice styles",
                      "Automatically animated",
                      "6 output languages",
                    ]}
                    credits={50}
                    url={`https://buy.stripe.com/dR63fjfYd3JAbjGcMN?client_reference_id=${context.user.account_id}`}
                  />
                </div>
              </div>
            </Row> :
            <div className="container-padding">
              <Row
                title="Please fill out the payment method below"
                fontCss
                customeStyle={{ fontSize: "26px", fontWeight: "bold", fontFamily: 'Poppins' }}
              >

                <div className="flex justify-around mt-10 form-width form-border" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <BillingCard onBack={()=>{
                    setShowCreditCard(false)
                  }} callBack={(data) => {
                    console.log(data.token.id)
                    setTokenId(data.token.id)
                    setShowCreditCard(false)
                  }} />
                </div>
              </Row>
            </div>
        }

      </Animate>

      {/* <div className='mt-4'>
          <Link url='/account/profile' text='Manage Your Account' />
        </div>
         */}
    </div>
  );
}

/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

// import React, { useContext } from "react";
// import {
//   Animate,
//   AuthContext,
//   Row,
//   Card,
//   PaymentForm,
//   usePlans,
//   useNavigate,
//   Event,
// } from "components/lib";

// export function SignupPlan(props) {
//   const context = useContext(AuthContext);
//   const navigate = useNavigate();
//   const plans = usePlans();
//   const plan = window.location.hash.substring(1);

//   if (!plans.data) return false;

//   return (
//     <div className="bg-yellow">
//       <Animate type="pop">
//         <Row
//           title="Select Your Plan"
//           fontCss
//           customeStyle={{ fontSize: "22px", fontWeight: "bold" }}
//         >
//           <Card loading={false} restrictWidth center>
//             <PaymentForm
//               data={{
//                 plan: {
//                   label: "Plan",
//                   type: "select",
//                   class: "bg-yellow-fade rounded-lg",
//                   options: plans.data.list,
//                   default: plan,
//                   required: true,
//                 },
//                 token: {
//                   label: "Credit Card",
//                   type: "creditcard",
//                   class: "rounded-lg",
//                   required: true,
//                 },
//               }}
//               url="/api/account/plan"
//               method="POST"
//               buttonText="Save Plan"
//               callback={(res) => {
//                 // save the plan to context, then redirect
//                 Event("selected_plan", { plan: res.data.plan });
//                 context.update({
//                   plan: res.data.plan,
//                   subscription: res.data.subscription,
//                 });
//                 navigate("/dashboard");
//               }}
//             />
//           </Card>
//         </Row>
//       </Animate>

//       {/* <div className='mt-4'>
//               <Link url='/account/profile' text='Manage Your Account' />
//             </div>
//              */}
//     </div>
//   );
// }

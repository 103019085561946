import React, { useContext, useState } from "react";
import { useNavigate, Button, useAPI, AuthContext, Link } from "components/lib";
import Axios from "axios";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { v4 as uuidv4 } from "uuid";
import { AvatarCardStep } from "./steps";
const Settings = require('../../../src/settings.json');

export function Avtarcard(props) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    promptMessage: '',
    promptMessage2: '',
    promptMessage3: '',
    voiceMessage: '',
    chooseLanguage: '',
    selectSlideAvatar: undefined,
  });
  const steps = 3;
  const user = useAPI("/api/user");
  const context = useContext(AuthContext);
  const credits = context?.user?.credits ?? 0;

  const createVideoAction = async () => {
    let uniqueId = uuidv4();
    if (!formData.selectSlideAvatar) {
      alert('Please select avatar');
      return;
    }
    if (props.goto === '/send-video') {
      navigate(
        `${props.goto}?uniqueid=${uniqueId}&avatar=${formData.selectSlideAvatar}&promptMessage=${formData.promptMessage}&promptMessage2=${formData.promptMessage2}&promptMessage3=${formData.promptMessage3}&voiceMessage=${formData.voiceMessage}&chooseLanguage=${formData.chooseLanguage}`
      );
    } else {
      const baseUrl = Settings[process.env.NODE_ENV].video_server_url;
      let promptMessage = [formData.promptMessage];
      if (formData.promptMessage2 !== '') promptMessage.push(formData.promptMessage2)
      if (formData.promptMessage3 !== '') promptMessage.push(formData.promptMessage3)
      let userInputData = {
        uniqueId: uniqueId,
        avatar: formData.selectSlideAvatar,
        promptMessage: promptMessage,
        voiceMessage: formData.voiceMessage,
        firstname: user.data.name,
        lastname: '',
        email: user.data.email,
        chooseLanguage: formData.chooseLanguage,
        authenticated: true,
      };
      Axios({
        method: 'POST',
        url: `api/video/generateVideoWithSound`,
        baseURL: `${baseUrl}`,
        data: userInputData,
        headers: {
          'Content-Type': 'application/json',
          Connection: 'keep-alive',
        },
      });
      navigate(
        `/inprogress?uniqueid=${uniqueId}&avatar=${formData.selectSlideAvatar}`
      );
    }
  };

  const handleContinueButtonPress = () => {
    if (currentStep === 1) {
      if (!formData.promptMessage) {
        alert('Please enter prompt message!');
        return;
      }
      if (formData.promptMessage.length <= 20) {
        alert('Please enter prompt message more than 20 characters!');
        return;
      }
    } else if (currentStep === 2) {
      if (!formData.voiceMessage) {
        alert('Please enter voice message!');
        return;
      }
      if (formData.voiceMessage.length <= 20) {
        alert('Please enter prompt message more than 20 characters!');
        return;
      }
      if (!formData.chooseLanguage) {
        alert('Please choose the language');
        return;
      }
    }

    currentStep === steps
      ? createVideoAction()
      : setCurrentStep((prevState) =>
          prevState < 3 ? prevState + 1 : prevState
        );
  };

  return (
    <div
      style={{
        width: '90%',
        maxWidth: 650,
        backgroundColor: "white",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "#ddd",
          height: 10,
        }}
      >
        <div
          style={{
            width: `${(currentStep / steps) * 100}%`,
            backgroundColor: "black",
            height: 10,
          }}
        />
      </div>
      <div style={{ padding: 32 }}>
        <AvatarCardStep
          steps={steps}
          currentStep={currentStep}
          formData={formData}
          setFormData={setFormData}
          userHasCredit={props?.userHasCredit}
          isPaidUser={props?.isPaidUser}
          user={props.user}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: 40,
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          {currentStep !== 1 && (
            <Button
              outline
              customWidth
              action={() =>
                setCurrentStep((prevState) =>
                  prevState > 1 ? prevState - 1 : prevState
                )
              }
              text={"Previous"}
              rounded
            />
          )}
          {props.free || credits > 0 ? (
            <Button
              customWidth
              action={handleContinueButtonPress}
              text={currentStep === steps ? "Create video" : "Continue"}
              rounded
            />
          ) : (
            <div>
              <span>Not enough credits! </span>{' '}
              <Link url="/account/billing" text="Buy more" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React, {useState} from 'react';

import stl from '../avtarcard.module.scss';
import blackgirl from '../images/blackgirl.png';
import girlwhairband from '../images/girlwhairband.png';
import salliAvatar from '../images/salliAvatar.png';
import kevinAvatar from '../images/kevinAvatar.png';
import SliderCard from '../slidercard';

const avatr = [
  {
    id: 'Salli',
    image: salliAvatar,
    voice:
      'https://tempanimatr.s3.amazonaws.com/lovo_animatr_sample_voices/Sharon+Huay.wav',
  },
  {
    id: 'Kim_Favatrerdowsi',
    image: blackgirl,
    voice:
      'https://tempanimatr.s3.amazonaws.com/lovo_animatr_sample_voices/Glenn+de+Jolla.wav',
  },
  {
    id: 'Caroline_Hughes',
    image: girlwhairband,
    voice:
      'https://tempanimatr.s3.amazonaws.com/lovo_animatr_sample_voices/Caroline+Hughes.wav',
  },
  {
    id: "Richard_Hall",
    image: kevinAvatar,
    voice: 'https://tempanimatr.s3.amazonaws.com/lovo_animatr_sample_voices/Richard+Hall.wav',
  },
];

export function VoiceSelectorStep(props) {
  const [slidecurrentPlaying, setSlidecurrentPlaying] = useState();

  const handleSlideCurrentPlaying = (id) => {
    setSlidecurrentPlaying(id);
  };

  const handleSlideSelectedAvatar = (id) => {
    props.setFormData({ ...props.formData, selectSlideAvatar: id });
  };

  return (
    <div>
      <div className={stl.headingContainer}>
        <div>
          <div
            style={{
              width: '100%',
              color: '#000',
              fontSize: '24px',
              fontWeight: '600',
            }}
          >
            (3/3) Pick a voice-over style
          </div>
          <div
            style={{
              width: '100%',
              textJustify: 'auto',
              color: '#6C757D',
              fontSize: 16,
              marginBottom: 16,
            }}
          >
            Hover over the avatars and listen to their voices. Then click the
            one you prefer to hear as voice-over. Your video comes with the
            voice only, you will not see the image of the talking avatar.
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '20px',
            flexWrap: 'wrap',
          }}
        >
          {avatr &&
            avatr.map((slideitem, i) => {
              return (
                <SliderCard
                  key={slideitem.id}
                  slideitem={slideitem}
                  slidecurrentPlaying={slidecurrentPlaying}
                  handleSlideCurrentPlaying={handleSlideCurrentPlaying}
                  selectSlideAvatar={props.formData.selectSlideAvatar}
                  handleSlideSelectedAvatar={handleSlideSelectedAvatar}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

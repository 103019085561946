import { Form, useNavigate, AuthContext } from "components/lib";
import React, { useContext } from "react";
import './card.module.scss'

function PlanCard(props) {
  const context = useContext(AuthContext);

  const navigate = useNavigate();
  let planName = props.planName ? props.planName : "Basic";
  let planFeatures = props.planFeatures
    ? props.planFeatures
    : [
      "1 video credit = up to 30 sec video",
      "Text to video (perfecting your script coming soon)",
      "4 different voice styles",
      "Automatically animated",
      "Built-in human voices for all requested languages",
    ];
  let buttonText = props.buttonText ? props.buttonText : "Choose";
  let price = props.price ? props.price : "20";

  return (
    <div className="w-full xl:w-3/3 lg:w-3/3 md:w-2/2 sm:w-2/2 mb-4 px-6 plan-card">
      <div
        className="py-5 px-4 bg-white border-2 border-black rounded-lg text-left content-center"
      >
        <h4
          className="text-2xl font-semibold text-center text-[#231F20] font-poppins"
        >
          {planName}
        </h4>
        <p className="text-[#231F20] pb-8 pt-2 text-center font-light font-poppins"><strong className="font-semibold">{props.credits} </strong>video credits</p>
        <ul className="flex flex-col mb-6">
          {planFeatures &&
            planFeatures.map((feature) => (
              <li className="flex items-center mb-4">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                <p
                  className="text-base font-semibold text-[#231F20] font-poppins"
                >
                  {feature}
                </p>
              </li>
            ))}
        </ul>
        <div className="flex justify-center items-center">
        <h4
          className="text-2xl font-semibold text-center text-[#231F20] mr-2 font-poppins"
        >${price}
        </h4>
          <span className="text-[#231F20] font-light text-base font-poppins">/ {props.credits} video credits</span>
        </div>
        <Form
          data={{
            plan: {
              type: "hidden",
              // value: props.plan,
              value: props.plan,
            },
            subscription: {
              type: "hidden",
              value: props.checked === false ? "monthly" : "yearly",
            },
            checked: {
              type: "hidden",
              value: props.checked,
            },
            token: {
              type: "hidden",
              value: {
                id: props.tokenId
              }
            }
          }}
          url={props.url}
          method="POST"
          stripe
          buttonName={buttonText}
          showCustomButtonCreditCard={props.plan !== 'free' && !props.tokenId.length ? () => {
            props.setShowCreditCard(true)
          } : false}
          fullWidth
          callback={(res) => {
            context.update({
              plan: res.data.plan,
              subscription: res.data.subscription,
            });
            navigate("/dashboard");
          }}
        />
      </div>
    </div>
  );
}

export default PlanCard;

const Style = {
  wrapper: "h-24",
  nav: "absolute top-1/2 -translate-y-1/2 right-6 ",
  logo: "absolute top-1/2 -translate-y-1/2 left-5 m0",
  transparent: "bg-none",
  color: "bg-header-bg",
  link: "mr-5 last:mr-0 no-underline",
  button: "inline-block",
};

export default Style;

import { Link } from 'components/lib';

export function VideoDescriptionStep(props) {
  const upgradePlanLink = props.user ? '/account/billing' : '/register';

  const handleMessageChange = (event) => {
    props.setFormData({ ...props.formData, promptMessage: event.target.value });
  };

  const handleMessageChange2 = (event) => {
    props.setFormData({
      ...props.formData,
      promptMessage2: event.target.value,
    });
  };

  const handleMessageChange3 = (event) => {
    props.setFormData({
      ...props.formData,
      promptMessage3: event.target.value,
    });
  };

  return (
    <div>
      <form>
        <div>
          <div
            style={{
              width: '100%',
              color: '#000',
              fontSize: '24px',
              fontWeight: '600',
              marginBottom: 10,
            }}
          >
            (1/3) Describe what you want to see in the video
          </div>
          <div
            style={{
              width: '100%',
              textJustify: 'auto',
              color: '#6C757D',
              fontSize: 16,
              marginBottom: 30,
            }}
          >
            Read carefully to get the best result! Each sentence is a building
            block of your video. You want to write 2 sentences for each scene.
            In each sentence you want to describe: the style (2D vector, 3D
            animation, energetic painting of, product shot of, die-cut sticker),
            the person/animal/item (portrait of a cute girl, photo of a poodle,
            panda toy), the setting (at the beach, Japanese city street) and the
            mood (joyful, cosy vintage, romantic), lighting (soft, smooth
            lighting), colors (soft pastel colors, vibrant colors).Tip: put
            “highly detailed” at the end of your sentences for best result.
          </div>
        </div>
        <p style={{ fontSize: 16, color: '#000814', marginBottom: 8 }}>
          Scene 1
        </p>
        <textarea
          name={'promptMessage'}
          onChange={handleMessageChange}
          value={props.formData.promptMessage}
          style={{
            width: '100%',
            height: '180px',
            lineHeight: '1.5',
            border: '1px solid #000',
            padding: '10px',
            borderRadius: '12px',
          }}
          className="enabled:bg-white disabled:bg-black"
        />

        <p
          style={{
            fontWeight: '500',
            fontSize: 16,
            color: '#6C757D',
            marginBottom: 30,
          }}
        >
          <span
            style={{
              color: '#231F20',
            }}
          >
            Example:{' '}
          </span>
          Anime style, joyful labrador in helmet, sci-fi setting, cinematic
          lighting, vibrant colors, highly detailed. Anime style, joyful poodle
          in helmet, sci-fi setting, cinematic lighting, pastel colors, highly
          detailed. Anime style, joyful husky in helmet, sci-fi setting,
          cinematic lighting, vibrant colors, highly detailed.
        </p>
        <p
          style={{
            fontSize: 16,
            color: '#000814',
            marginBottom: 8,
            marginTop: 16,
          }}
        >
          Scene 2{' '}
          <small style={{ fontSize: 16, color: '#6C757D' }}>(optional)</small>
        </p>
        {props.isPaidUser ? ( //TODO: find better approach to this situation
          <textarea
            disabled={!props?.isPaidUser}
            name={'promptMessage2'}
            key={'promptMessage2'}
            onChange={handleMessageChange2}
            value={props.formData.promptMessage2}
            style={{
              width: '100%',
              height: '180px',
              lineHeight: '1.5',
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '12px',
              marginBottom: 16,
            }}
            className="disabled:bg-gray-200"
          />
        ) : (
          <div
            name={'promptMessage2'}
            key={'promptMessage2'}
            onChange={handleMessageChange2}
            value={props.formData.promptMessage2}
            style={{
              width: '100%',
              height: '180px',
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '12px',
              marginBottom: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="bg-gray-200"
          >
            <Link text={'Premium Feature - Unlock Now'} url={upgradePlanLink} />
          </div>
        )}
        <p style={{ fontSize: 16, color: '#000814', marginBottom: 8, marginTop: 30 }}>
          Scene 3{' '}
          <small style={{ fontSize: 16, color: '#6C757D' }}>(optional)</small>
        </p>
        {props.isPaidUser ? (
          <textarea
            disabled={!props?.isPaidUser}
            name={'promptMessage3'}
            key={'promptMessage3'}
            onChange={handleMessageChange3}
            value={props.formData.promptMessage3}
            style={{
              width: '100%',
              height: '180px',
              lineHeight: '1.5',
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '12px',
              marginBottom: 16,
            }}
            className="disabled:bg-gray-200"
          />
        ) : (
          <div
            name={'promptMessage3'}
            key={'promptMessage3'}
            onChange={handleMessageChange3}
            value={props.formData.promptMessage3}
            style={{
              width: '100%',
              height: '180px',
              border: '1px solid #000',
              padding: '10px',
              borderRadius: '12px',
              marginBottom: 16,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className="bg-gray-200"
          >
            <Link text={'Premium Feature - Unlock Now'} url={upgradePlanLink} />
          </div>
        )}
      </form>
    </div>
  );
}

import { Link } from 'components/lib';
import React, { useCallback, useEffect, useState } from 'react';
import { Select } from '../../form/select/select';

//TODO: a cleanup on special characters at the prompt
export function NarrativeDescriptionStep(props) {
  const upgradePlanLink = props.user ? '/account/billing' : '/register';

  const handleVoiceMessageChange = useCallback((newMessage) => {
    // remove all special characters, but leave spaces, numbers, letters, and some punctuation
    const sanitizedVoiceMessage = newMessage.replace(/[^a-zA-Z0-9 .,?!%&()-_+=:;]/giu, '');

    props.setFormData({ ...props.formData, voiceMessage: sanitizedVoiceMessage });
  }, [props])
  
  const [chooseLanguage, setChooseLanguage] = useState(
    props.formData.chooseLanguage
  );
  const maxCharSubtitle = props?.isPaidUser
    ? 'Max 400 characters = up to 30 sec length video'
    : 'Max 67 characters = max 5 seconds long video';

  const handleLanguageChange = useCallback((newLanguage) => {
    props.setFormData({ ...props.formData, chooseLanguage: newLanguage });
  }, [props])

  useEffect(() => {
    if (props.formData.chooseLanguage === '') {
      props.setFormData({ ...props.formData, chooseLanguage: 'en' });
    }
  }, [props.formData.chooseLanguage, props])

  const options = [
    {
      value: 'en',
      label: 'English',
    },
    {
      value: 'fr',
      label: 'French',
    },
    {
      value: 'es',
      label: 'Spanish',
    },
    {
      value: 'de',
      label: 'German',
    },
    {
      value: 'nl',
      label: 'Dutch',
    },
    {
      value: 'pl',
      label: 'Polish',
    },
  ];

  return (
    <div>
      <div>
        <div
          style={{
            width: '100%',
            color: '#000',
            fontSize: '24px',
            fontWeight: '600',
          }}
        >
          (2/3) Write what you want to hear as voice-over
        </div>
        <div
          style={{
            width: '100%',
            textJustify: 'auto',
            color: '#6C757D',
            fontSize: 16,
            marginBottom: 8,
          }}
        >
          What do you want to hear while the video is running? What is the
          message of your video? What do you want to tell? This will be the
          narration of the short animation.
        </div>
      </div>
      <textarea
        name="promptMessage"
        onChange={(changeEvent) => handleVoiceMessageChange(changeEvent.target.value)}
        value={props.formData.voiceMessage}
        style={{
          width: '100%',
          height: '180px',
          lineHeight: '1.5',
          border: '1px solid #000',
          padding: '10px',
          borderRadius: '12px',
        }}
        maxLength={props?.isPaidUser ? 400 : 67}
      />
      <div
        style={{
          width: '100%',
          textJustify: 'auto',
          color: '#6C757D',
          fontSize: 16,
          marginBottom: 8,
        }}
      >
        {maxCharSubtitle}{' '}
        {!props.isPaidUser && (
          <Link text={'Upgrade'} url={upgradePlanLink} />
        )}
      </div>
      <p
        style={{
          fontWeight: '500',
          fontSize: 16,
          color: '#6C757D',
        }}
      >
        <span
          style={{
            color: '#231F20',
            paddingRight: 10,
          }}
        >
          Example:
        </span>
        Hi there, this time I am reaching out to you with a video,
        cool eh?
      </p>
      <div style={{ paddingTop: 24 }}>
        <Select
          label="Choose language"
          description="This will be the language of your video's voice-over."
          options={options}
          default={props?.formData?.chooseLanguage}
          callback={(newLanguage) => handleLanguageChange(newLanguage)}
          onChange={(newLanguage) => handleLanguageChange(newLanguage)}
          required
        />
      </div>
    </div>
  );
}

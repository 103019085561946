import React from "react";
import { Animate, Avtarcard, Link, Seo } from "components/lib";

import stl from './createVideo.module.scss'

export function Createfreevideo(props) {
  let route = window.location.href
  return (
    <Animate type="pop">
      <Seo
        title="Create a free Animatr Video"
        description="Create a free animatr video"
      />
      <div className={stl.container}>
        <p className={stl.headingText}>Create your {route.includes('free') && 'free'}</p>
        <p className={stl.descriptionText}>
          Animatr video!
        </p>
        <Avtarcard
          restrictWidth
          center
          className="mt-16"
          title={"Choose the Voice of the voice-over "}
          label1={
            "Your avatar comes with a voice, click the audio icon in the upper right side of the picture to listen to it the avatar's voice."
          }
          title2={"Create your script for the video :"}
          label2={
            "You can type or paste your text of max. 100 words , which will be transformed into a video"
          }
          goto="/send-video"
          free
        ></Avtarcard>
        <p
          style={{
            paddingTop: 40,
            textDecoration: 'underline',
            fontWeight: '600',
          }}
        >
          Already Registered?{' '}
          <Link className={stl.linkContainer} url="/signin" text="Sign In" />
        </p>
      </div>

    </Animate>
  );
}

import {
  Animate,
  Card,
  Form,
  Form2,
  Link,
  Seo,
  useNavigate,
} from "components/lib";
import React, { useState } from "react";
import Axios from "axios";
import stl from './createVideo.module.scss'
const Settings = require('../../../../src/settings.json')

function SendVideo(props) {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const [form] = useState({
    firstname: {
      label: "First Name",
      type: "text",
      class: "rounded-lg",
      required: true,
    },
    lastname: {
      label: "Last Name",
      type: "text",
      class: "rounded-lg",
      required: true,
    },
    email: {
      label: "Your Email",
      type: "email",
      class: "rounded-lg",
      required: true,
    },
  });
  return (
    <Animate type="pop">
      <Seo
        title="Send Video"
        description="You will get you video in your inbox"
      />
      <div className={stl.container} style={{ marginBottom: 150 }} >
        <p className={stl.headingText}>You will get your video in your</p>
        <p className={stl.descriptionText}>
          inbox in just a few minutes
        </p>

        <Card center className={stl.sendVideoFormBox}>
          <Form2
            className={stl.formContainer}
            data={form}
            method="POST"
            buttonText={"Access your video"}
            buttonCustomClass={stl.buttonContainer}
            callback={(res) => {
              let params = new URLSearchParams(window.location.search);
              let userInputData = {
                uniqueId: params.get('uniqueid'),
                avatar: params.get('avatar'),
                promptMessage: [params.get('promptMessage')],
                voiceMessage: params.get('voiceMessage'),
                firstname: form.firstname.value,
                lastname: form.lastname.value,
                email: form.email.value,
                chooseLanguage: params.get('chooseLanguage'),
                authenticated: false
              };
              const baseUrl = Settings[process.env.NODE_ENV].video_server_url;
              Axios({
                method: 'POST',
                url: `api/video/generateVideoWithSound`,
                baseURL: `${baseUrl}`,
                data: userInputData,
                headers: {
                    'Content-Type': 'application/json',
                    'Connection': 'keep-alive'
                }
              });
              navigate(`/inprogress?uniqueid=${params.get('uniqueid')}&firstname=${form.firstname.value}&lastname=${form.lastname.value}&email=${form.email.value}`);
            }}
          />
          <div className="flex justify-center">
            <Link
              url="/create-free-video"
              text="Go Back"
              className="text-black font-bold"
            />
          </div>
        </Card>

      </div>
    </Animate>
  );
}

export default SendVideo;

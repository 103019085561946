/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import React, { Fragment, useContext, useState } from 'react';
import {
  AuthContext,
  AccountNav,
  Animate,
} from 'components/lib';
import PlanCard from 'components/card/planCard';
import { getEnv } from 'app/util';
const Settings = require('../../../../src/settings.json');

// const Messages = require('./messages.json');

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);

  const [showCreditCard, setShowCreditCard] = useState(false)
  // fetch subscription
  // const subscription = useAPI('/api/account/subscription');
  // const isPaid = context.user.plan === 'free' ? false : true;
  // const labels = ['Plan'];
  // if (isPaid) labels.push('Card', 'Invoices');

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        {/* {!subscription?.loading &&
          subscription?.data?.status !== 'active' &&
          subscription?.data?.status !== 'trialing' && (
            <Message {...Messages[subscription.data?.status]} />
          )} */}

        {/* <TabView name="Billing"> */}
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
          >
            <div className="grid grid-cols-2 gap-4 mb-12 -mx-6 max-w-6xl plan-card-container">
              <PlanCard
                planName="Basic"
                price="29"
                buttonText="Buy now"
                plan="price_1L0XlWG2u4eiINVDXwlSHLKn"
                checked={'checked'}
                tokenId={''}
                planFeatures={[
                  '1 video credit = up to 30 sec video',
                  'Text to video (perfecting your script coming soon)',
                  '4 different voice styles',
                  'Automatically animated',
                  '6 output languages',
                ]}
                setShowCreditCard={setShowCreditCard}
                credits={10}
                url={`${Settings[getEnv()].stripe.STRIPE_BASIC_URL}?client_reference_id=${context.user.account_id}`}
              />
              <PlanCard
                planName="Pro"
                price="89"
                tokenId={''}
                buttonText="Buy now"
                plan="price_1L9tvSG2u4eiINVDFz1sVmRZ"
                checked={'checked'}
                setShowCreditCard={setShowCreditCard}
                planFeatures={[
                  '1 video credit = up to 30 sec video',
                  'Text to video (perfecting your script coming soon)',
                  '4 different voice styles',
                  'Automatically animated',
                  '6 output languages',
                ]}
                credits={50}
                url={`${Settings[getEnv()].stripe.STRIPE_PRO_URL}?client_reference_id=${context.user.account_id}`}
              />
            </div>
          </div>

          {/* <BillingPlan subscription={subscription} /> */}

          {/* {isPaid && <BillingCard />} */}

          {/* {isPaid && <BillingInvoices />} */}
        {/* </TabView> */}
      </Animate>
    </Fragment>
  );
}

/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext, Button, Icon, Link } from "components/lib";
import IconDark from "../../logo/images/lcon_sm_dark.png";
import LogoDark from "../../logo/images/animatrLogo.png";
import IconLight from "../../logo/images/Icon_sm_light.png";
import "./app.scss";

const currentUrl = window.location.href;

export function AppNav(props) {
  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded
  const context = useContext(AuthContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    setWindowWidth(window.innerWidth)

    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  return (
    <nav
      className={`sidebar popup ${open && "open"} ${expanded && "expanded"}`}
      onMouseEnter={(e) => setExpanded(true)}
      onMouseLeave={(e) => setExpanded(false)}
    >
      {windowWidth <= 600 ? (
        <div onClick={() => setOpen(!open)}>
          <Button
            icon={open ? "x" : "menu"}
            color={"dark"}
            size={24}
            className="btn-togglenav"
            action={() => setOpen(!open)}
          />
          <img src={IconLight} alt="icon" className="mobile-logo" />
        </div>
      ) : (
        <div className="logo-container">
          {
            expanded ? 
            <img src={LogoDark} alt="icon" style={{ width: 160, height: 40 }} /> :
            <img src={IconDark} alt="icon" style={{ width: 47, height: 47 }} />
          }
        </div>
      )}
      <section className="nav-links">
        {props.items?.map((item) => {
          if (item.link) {
            return (
              <NavLink
                key={item.label}
                to={item.link}
                style={{ width: 100 / props.items.length + "%" }}
              >
                {item.icon && (
                  <Icon
                    className={`icon ${!currentUrl.includes(item.link) ? 'fill-pink-300' : 'fill-yellow-300' } `}
                    image={item.icon}
                    size={open ? 15 : 18}
                    // color={"yellow"}
                    color={currentUrl.includes(item.link) ? "yellow" : "pink"}
                  />
                )}
                {item.label && <span className="label">{item.label}</span>}
              </NavLink>
            );
          }

          return (
            <div key={item.label} onClick={item.action}>
              {item.icon && (
                <Icon
                  className="icon"
                  image={item.icon}
                  size={open ? 15 : 18}
                  color={open ? "yellow" : "pink"}

                  // color={ open ? 'dark' : 'light' }
                  // color={"yellow"}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          );
        })}
        <button className="credits">
          <Link
            url="/account/billing"
            className="credit-link"
            text={`
              ${context.user.credits} video credit${
                context.user.credits.length === 1 ? '' : 's'
              } left!`
            }
          />
          <Link
            url="/account/billing"
            className="credit-link"
            text={`Buy more credits`}
          />
        </button>
      </section>
    </nav>
  );
}
